
<template>
  <v-container>
    <v-row cols="12" class="mb-12 mt-12">
        <v-col cols="12">
            <h1 style="line-height: 2rem;">Error al validar cuenta de usuario</h1>
            <br>
            <p>El enlace de verificacion de la cuenta ha caducado. Ingresa tu correo electronico para enviar de nuevo el elace de verificacion.</p>
            <form cols="12">
                <v-text-field
                v-model.trim="form.email"
                :label="`Correo electronico`"
                outlined
                hide-details="auto"
                :error="error"
                :error-messages="errors('email')"
                :class="{ 'mb-3': !!!errors('email').length }"
                @blur="$v.form.email.$touch()"
                />
            </form>
            <v-col cols="12">
                <v-btn
                color="primary"
                dark
                class="button--form"
                type="submit"
                :loading="loading"
                @click="sendEmail"
                >
                Enviar enlace de verificación
                </v-btn>
            </v-col>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'EmailErrorValidation',
  validations: {
    form: {
      email: { required, email }
    }
  },
  mixins: [validationMixin],
  data () {
    return {
      form: {
        email: ''
      },
      error: false,
      errorMessage: '',
      loading: false
    }
  },
  computed: {
    errors () {
      return (input) => {
        const errors = []
        if (input === 'email') {
          !this.$v.form[input].email && errors.push(this.$t('validations.validEmail'))
        }
        return errors
      }
    }
  },
  created () {
    this.sendEmail()
  },
  methods: {
    async sendEmail () {
      console.log('Enviar correo de verificacion')
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const { success, message } = await this.$store.dispatch('auth/resendVerificacionEmail', this.form)
          if (await success) {
            this.loading = false
            this.error = false
            this.$router.replace({ name: 'Home' })
          } else {
            this.error = true
            this.errorMessage = await message
          }
        } catch (error) {}
      }
    }
  }
}
</script>

<style>
</style>
